<template>
	<div>
		<creatsuccess :msg="showtype" @recovery="recovery">
			<div class="editgiftdetails" v-loading="detailLoading">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>基本信息</span>
						</div>
						<div class="content">
							<el-form-item label="活动时间：" required>
								<el-form-item prop="StartTime" class="el-form-date-pick">
									<el-date-picker v-model="ruleForm.StartTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
									default-time="00:00:00" type="datetime" placeholder="开始时间" :picker-options='maxOptions' :disabled="currentEditType==1&&currentEditStatus=='进行中'">
									</el-date-picker>
								</el-form-item>
								<span style="margin:0 10px;">~</span>
								<el-form-item prop="EndTime" class="el-form-date-pick">
									<el-date-picker v-model="ruleForm.EndTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
									placeholder="结束时间" :picker-options='minOptions' default-time="23:59:59">
									</el-date-picker>
								</el-form-item>
							</el-form-item>

							<el-form-item label="成团人数:" prop="GroupMemberNum">
								<el-input type="text" v-model="ruleForm.GroupMemberNum" :disabled="currentEditStatus=='进行中' && currentEditType==1" @input='onlyclustNum(ruleForm.GroupMemberNum,1)' style="width: 150px;"></el-input>
								人
							</el-form-item>
							<div style="color: #909399;margin-top:30px;font-size: 14px;margin-left:148px;margin-bottom: 20px;">拼团成功，需要参与购买的人数；成团人数大于1人</div>

							<el-form-item label="成团有效期:" prop="EffectiveGroupDay">
								<el-input type="text" v-model="ruleForm.EffectiveGroupDay" :disabled="currentEditStatus=='进行中' && currentEditType==1" @input='onlyclustNum(ruleForm.EffectiveGroupDay,2)'
								style="width: 150px;"></el-input>
								天
								
							</el-form-item>
							<div style="color: #909399;margin-top:30px;font-size: 14px;margin-left:148px;margin-bottom: 20px;">设置成团有效期，在该时间内未拼团成功的团购，拼团失败，支付的款项原路退还，成团有效时间不可为0</div>
							<el-form-item label="活动限购">
								<div>
									<el-radio v-model="ruleForm.radioLimit" label="0" @change='noChangeLimit'>不限购</el-radio>
								</div>
								<div style="display: flex;align-items: center;margin-bottom:21px;">
									<el-radio v-model="ruleForm.radioLimit" label="1" @change='YesChangeLimit'>
										限购
									</el-radio>
									<el-form-item :prop="ruleForm.radioLimit==1?'Quota':'nocheck'" :rules='ruleForm.radioLimit==1?rules.Quota:rules.nocheck'>
										<el-input :disabled="ruleForm.radioLimit==0" v-model="ruleForm.Quota" style="width: 100px;margin: 0 5px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
										onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件/人
									</el-form-item>
								</div>
								<div style="color: #909399;margin-top: 10px;">活动期间每个用户拼团商品限购数量</div>
							</el-form-item>
							<el-form-item label="优惠券叠加：" required prop='IsSuperpositionCoupon'>
								<div>
									<el-radio v-model="ruleForm.IsSuperpositionCoupon" :label="true">叠加</el-radio>
								</div>
								<div>
									<el-radio v-model="ruleForm.IsSuperpositionCoupon" :label="false">不叠加</el-radio>
								</div>
								<div style="color: #909399;margin-top: 10px;">开启优惠券叠加后，本场活动商品可同时拼团价、优惠券优惠</div>
							</el-form-item>


						</div>
					</el-card>

					<el-card class="box-card" style="margin-top: 10px;">
						<div slot="header" class="clearfix">
							<span>拼团商品</span>
						</div>
						<div class="content">
							<el-form-item label="活动商品：" required>
								<!-- v-if='currentEditStatus=="未开始"' -->
								<span :class="{'is-error-button':!ruleForm.ProductSpecList.length}" style="color:#409EFF;margin-left:15px;cursor: pointer;"
								@click="handlePrizePro" v-if="((currentEditStatus=='未开始' ||currentEditStatus=='' )&& currentEditType == 1) || currentEditType == 2">选择商品</span>
								<div class="productInfo" style="display: flex;width: 100%;" v-if='proCheckedId'>
									<img :src="imgUrl + ruleForm.ImgUrl +'@!cut64'" v-if='ruleForm.ImgUrl' />
									<div style="display: flex;margin-left:10px;width: 60%;">
										<!-- v-if="ruleForm.ProductType==1" -->
										<div style="color: #f00;font-size: 12px;width:40px" v-if="ruleForm.ProductType==1">[组合]</div>
										<div style="width:90%;" class="ellipsisStyle">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{ruleForm.ProductName}}</pre>
										</div>
									</div>
								</div>
								<!-- 抽奖活动表格 -->
								<el-table :data="ruleForm.ProductSpecList" style="width:1000px;margin:15px 0 50px 0" v-loading="loading" ref="multipleTable"
								:row-key="rowKeys" class="specialErrorTable">
									<el-table-column prop="Name" label="规格">
										<template slot-scope="scope">
											<div v-if='scope.row.IsDefault'>默认规格</div>
											<div class="oneSpless ellipsisCommon" v-else>
												{{scope.row.SpecValue}}
												<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="Barcode" label="条形码">
										<template slot-scope="scope">
											<div>{{scope.row.Barcode || '— —'}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="RoomId" label="价格">
										<template slot-scope="scope">
											<div>￥{{scope.row.Price}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="RetailPriceMin " label="建议最低零售价" v-if="IsSupplierProduct">
										<template slot-scope="scope">
											<div>￥{{scope.row.RetailPriceMin}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="Stock " label="库存">
										<template slot-scope="scope">
											<div>{{scope.row.Stock}}</div>
										</template>
									</el-table-column>

									<el-table-column prop="GroupPrice" label="拼团价">
										<template slot-scope="scope">

											<el-form-item inline-message :prop="'ProductSpecList.' +scope.$index + '.GroupPrice'" :rules='rules.GroupPrice'
											label-width='0'>
												<!-- <div style="display: flex;align-items: center;">
													<label style="padding:0 7px;background: #F5F5F5;font-size: 13px;">单笔充值</label>
													<el-input v-model="scope.row.ThresholdMoney" :disabled="!ruleForm.IsOpenMallTopUp" type="text" style="width:150px;"
													@input="scope.row.ThresholdMoney=validateFloat(scope.row.ThresholdMoney)"></el-input>
												</div> -->
												<div>
													<el-input type="text" v-model="scope.row.GroupPrice" @input='onlyNumber(scope.row.GroupPrice,scope.$index)'
													style="width: 100px;"></el-input> 元
												</div>
											</el-form-item>

										</template>
									</el-table-column>
								</el-table>
							</el-form-item>
						</div>
					</el-card>

					<el-card class="box-card" style="margin-top: 10px;margin-bottom: 100px;">
						<div slot="header" class="clearfix">
							<span>其他设置</span>
						</div>
						<div class="content">
							<el-form-item label="一键参团：">
								<div>
									<el-radio :label="true" v-model="ruleForm.IsOpenActiveGroup">开启</el-radio>
									<el-radio :label="false" v-model="ruleForm.IsOpenActiveGroup">关闭</el-radio>
								</div>
								<div style="color: #909399;margin-top: -5px;">开启一键参团之后，买家可在拼团商品详情页看到未成团的列表，并且直接参团。
									<el-button type='text' @click='CheckExample("https://cdn.dkycn.cn/Images/sellGroupImg.png")'>点击查看示例</el-button>
								</div>
							</el-form-item>
							<el-form-item label="官方团：" v-if="ruleForm.IsOpenActiveGroup">
								<div>
									<el-checkbox v-model="ruleForm.IsOnlyShowOfficialGroup" :label="true">一键参团区域仅展示官方团</el-checkbox>
								</div>
								<div style="color: #909399;line-height:1.5">1.选中后，买家在拼团商品详情页仅可看到官方团，非官方团不可见。
									<el-popover
										placement="bottom"
										trigger="click">
										<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/editsellGroupDetail-keytuxedo.png" style="width:375px" alt="">
										<el-button type="text" style="margin:0px;padding:0px" slot="reference">查看示例</el-button>
									</el-popover>
								</div>
								<div style="color: #909399;line-height:1.5">2.活动保存成功后，前往活动列表→更多→团列表，将指定团【设为官方团】</div>
							</el-form-item>
							<el-form-item label="团长免单：">
								<div>
									<el-radio :label="true" v-model="ruleForm.IsOpenGroupLeaderFree" @change='changeTeamfreeTrue' :disabled="ruleForm.IsOpenSimulationGroup?true:false">开启</el-radio>
								
									<el-radio :label="false" v-model="ruleForm.IsOpenGroupLeaderFree" :disabled="ruleForm.IsOpenSimulationGroup?true:false">关闭</el-radio>
								</div>
								<div style="color: #909399;margin-top: -5px;">若顾客开团并购买多件商品，仅1件商品参与团长免单。</div>
							</el-form-item>
							<el-form-item label="模拟成团：">
								<div>
									<el-radio :label="true" v-model="ruleForm.IsOpenSimulationGroup" @change='changeTestTrue' :disabled="ruleForm.IsOpenGroupLeaderFree?true:false">开启</el-radio>
								
									<el-radio :label="false" v-model="ruleForm.IsOpenSimulationGroup" :disabled="ruleForm.IsOpenGroupLeaderFree?true:false">关闭</el-radio>
								</div>
								<div style="color: #909399;margin-top: -5px;">开启模拟成团后，成团有效期内人数未满的团，系统将会模拟“匿名买家”凑满人数，使该团成团。 你只需要对已付款参团的真实买家发货。</div>
							</el-form-item>
							<el-form-item label="成团奖励：" prop="ActivityCouponList" :rules="ruleForm.IsSendGroupSuccessReward?rules.ActivityCouponList:rules.nocheck" class="nostar">
								<div>
									<el-radio :label="true" v-model="ruleForm.IsSendGroupSuccessReward">开启</el-radio>
									<el-radio :label="false" v-model="ruleForm.IsSendGroupSuccessReward">关闭</el-radio>
								</div>
								<div style="color: #909399;margin-top: 0px;line-height:1.5">多人组团成功后，发放配置的奖励</div>
								<div v-if="ruleForm.IsSendGroupSuccessReward">
									<el-button type="text" style="margin:0px;padding:0px" @click="selectCouponShow=true">选择优惠券</el-button>
									<div style="color: #909399;margin-top: 0px;line-height:1.5">若优惠券剩余数量小于赠送数量，优惠券将赠送失败。请关注优惠券剩余数量并及时补充。</div>
								</div>
								<div style="margin-top:10px" v-if="ruleForm.ActivityCouponList&&ruleForm.ActivityCouponList.length&&ruleForm.IsSendGroupSuccessReward">
									<el-table :data="ruleForm.tablelist">
										<el-table-column prop="" label="优惠券">
											<template slot-scope="scope">
												<div class="dialog-name-content">
													<div class="right">
														<div class="name">{{scope.row.CouponName}}</div>
														<div class = "errtips" v-if="scope.row.IsFailure">已失效</div>
                            <div class = "errtips" v-else-if="scope.row.LeaveCount<=0">数量=0</div>
													</div>
												</div>
											</template>
										</el-table-column>
										<el-table-column prop="UseTypeValue" label="类型"></el-table-column>
										<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
										<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
										<el-table-column prop="" label="赠送数量/人" width="180">
											<template slot-scope="scope">
												<el-form-item label-width="0px" :key="scope.row.Id" class="relativeErrMsg" :prop="'tablelist.'+scope.$index+'.CouponCount'" :rules="rules.CouponCount">
													<el-input :key="scope.row.Id+'input'"
														style="margin:0px 0px 10px 0px;width:140px;"
														v-model="scope.row.CouponCount"
														onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value=='-'){value =''}"
														onblur="this.v();if (value=='-'){value =''}">
													</el-input>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column prop="" label="操作">
											<template slot-scope="scope">
												<el-button type="text" style="color:#f56c6c" @click="removecoupon(scope.row)">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
									<div style="text-align:right;margin-top:10px">
										<el-pagination
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
											:current-page="currentPage"
											:page-sizes="[5, 10, 15, 20]"
											:page-size="pagesize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="couponstotal">
										</el-pagination>
									</div>
								</div>
							</el-form-item>
							<el-form-item label="推荐商品：" prop="RecommendProudctList" :rules="rules.RecommendProudctList">
								<!-- <el-radio-group v-model="ruleForm.RecommendProudctType" style="margin-top:12px"> -->
									<div>
										<el-radio :label="0" v-model="ruleForm.RecommendProudctType">推荐其他拼团活动商品</el-radio>
									</div>
									<div>
										<el-radio :label="1" v-model="ruleForm.RecommendProudctType">自定义推荐</el-radio>
										<div style="color: #909399;margin-top: -10px">
											最多添加50个商品；拖拽已选择商品可排序
										</div>
										<div v-if="ruleForm.RecommendProudctType==1">
											<el-button type="text" @click="addProductDialogShow=true">选择商品</el-button>
											<draggable :list="ruleForm.RecommendProudctList" :options="{animation: 100, handle:'.demo'}" class="shoplist-box">
												<div class="demo" v-for="(v,i) in ruleForm.RecommendProudctList" :key="i">
													<div class="subcoat">
														<div class="close" @click="delshop(i)">
															<i class="el-icon-error" style="color:#cccccc"></i>
														</div>
														<img :src="imgUrl+v.ImgUrl" alt="">
														<div class="ellipsis font-box">
															{{v.Name}}
														</div>
													</div>
												</div>
											</draggable>
										</div>
									</div>
									<div>
										<el-radio :label="2" v-model="ruleForm.RecommendProudctType">猜你喜欢</el-radio>
										<div style="color: #909399;margin-top: -10px;">
											基于系统算法，根据消费者浏览、下单等行为推荐商品
										</div>
									</div>
								<!-- </el-radio-group> -->
							</el-form-item>
							<el-form-item label="图片广告：">
								<div class="flex-row">
									<el-upload :action="imgapi" accept="image/*" list-type="picture-card" :show-file-list="false" :on-success="(e)=>{onSuccess(e,'AdvertisementImg')}"
										:before-upload="(e)=>{return beforeUpload(e,2,'图片')}" class="w750-h180">
										<img v-if="ruleForm.AdvertisementImg" :src="imgUrl+ruleForm.AdvertisementImg" style="width:100%;height:100%;" alt="">
										<i class="el-icon-plus" v-else></i>
									</el-upload>
									<div style="margin-left:10px">
										<div style="color: #909399;line-height:1.5">
											1.图片建议尺寸：宽750px，高180px
										</div>
										<div style="color: #909399;line-height:1.5">
											2.图片大小请控制在2M以内
										</div>
										<div style="color: #909399;line-height:1.5">
											3.支持jpg，png，gif格式
										</div>
										<el-button type="text" @click="onSuccess([''],'AdvertisementImg')">清空</el-button>
									</div>
								</div>
								<div class="flex-row">
									<span>图片链接：</span>
									<el-button type="text" @click="shopVisible=true">{{ruleForm.PagePath?ruleForm.PageName:'请选择链接页面'}}</el-button>
									<el-button type="text" v-if="ruleForm.PagePath" @click="removelink">
										<i class="el-icon-close"></i>
									</el-button>
									<el-button type="text" @click="shopVisible=true">
										<i class="el-icon-arrow-down"></i>
									</el-button>
								</div>
							</el-form-item>
							<el-form-item label="私域涨粉：">
								<el-radio-group v-model="ruleForm.IsOpenIncreaseFans">
									<el-radio :label="true">开启</el-radio>
									<el-radio :label="false">关闭</el-radio>
								</el-radio-group>
								<div style="color: #909399;margin-top: -10px">
									开启后，上传员工企业微信/个微等二维码，引导客户添加好友。
									<!-- <el-button type="text" @click="CheckExample('')">查看示例</el-button> -->
								</div>
								<div class="flex-row" style="gap:100px" v-if="ruleForm.IsOpenIncreaseFans">
									<div>
										<div>涨粉二维码</div>
										<div class="flex-row">
											<el-form-item label="" label-width="0px" prop="IncreaseFansQrCode"
											 :rules="ruleForm.IsOpenIncreaseFans?rules.IncreaseFansQrCode:rules.checkno">
												<el-upload :action="imgapi" accept="image/*" list-type="picture-card" :show-file-list="false" 
													:on-success="(e)=>{onSuccess(e,'IncreaseFansQrCode')}"
													:before-upload="(e)=>{return beforeUpload(e,2,'涨粉二维码')}" class="w80-h80">
													<img v-if="ruleForm.IncreaseFansQrCode" :src="imgUrl+ruleForm.IncreaseFansQrCode" style="width:100%;height:100%;" alt="">
													<i class="el-icon-plus" v-else></i>
												</el-upload>
											</el-form-item>
											<div style="margin-left:10px">
												<div style="color: #909399;line-height:1.5">
													1.图片大小请控制在2M以内
												</div>
												<div style="color: #909399;line-height:1.5">
													2.支持jpg，png，gif格式
												</div>
											</div>
										</div>
									</div>
									<div>
										<div>涨粉入口图片</div>
										<div class="flex-row">
											<el-form-item label="" label-width="0px" prop="IncreaseFansEntranceImg"
												:rules="ruleForm.IsOpenIncreaseFans?rules.IncreaseFansEntranceImg:rules.checkno">
												<el-upload :action="imgapi" accept="image/*" list-type="picture-card" :show-file-list="false"
												  :on-success="(e)=>{onSuccess(e,'IncreaseFansEntranceImg')}"
													:before-upload="(e)=>{return beforeUpload(e,2,'涨粉入口图片')}" class="w750-h180">
													<img v-if="ruleForm.IncreaseFansEntranceImg" :src="imgUrl+ruleForm.IncreaseFansEntranceImg" style="width:100%;height:100%;" alt="">
													<i class="el-icon-plus" v-else></i>
												</el-upload>
											</el-form-item>
											<div style="margin-left:10px">
												<div style="color: #909399;line-height:1.5">
													1.图片建议尺寸：宽750px，高180px
												</div>
												<div style="color: #909399;line-height:1.5">
													2.图片大小请控制在2M以内
												</div>
												<div style="color: #909399;line-height:1.5">
													3.支持jpg，png，gif格式
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-form-item>
						</div>
					</el-card>
				</el-form>

				<!-- 选择商品-->
				<el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" class="dialog data-dialog">
					<select-produce :proCheckedId='proCheckedId' :couponData="couponData" @getSelection='getSelectPros' :Num='1'></select-produce>
				</el-dialog>
				<!-- 保存商品冲突 -->
				<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px" class="dialog data-dialog">
					<div>
						<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
							<el-table-column :key="20" label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
										<div class="right">
											<div class="name">
												{{scope.row.ProductName}}
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="21" prop="Remark" label="冲突信息"></el-table-column>
						</el-table>
					</div>
					<div style="text-align:center;margin-top:20px;">
						<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定</el-button>
					</div>
				</el-dialog>
				<el-dialog :visible.sync="lookVisable" width="570px" title="查看示例" :show-close="false">
					<div  style="text-align: center;"><img :src="lookPicture" alt="" style="width:300px;height:600px;"></div>
					
				</el-dialog>
				<!-- <el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
					<select-coupon api="activityMarkdownGiftChoseCoupon" :couponData="{}"
						:params="['CouponName', 'ActivityCouponUseType']"
						:selectedData="ruleForm.ActivityCouponList" @getSelectList="getSelectCouponList"
						:visible.sync="selectCouponShow" v-if="selectCouponShow"></select-coupon>
				</el-dialog> -->

				<couponsdialog :visible.sync="selectCouponShow" @confirm="getSelectCouponList" @close="selectCouponShow = false"
     		:thelist="ruleForm.ActivityCouponList"></couponsdialog>

				
				<!-- 选择商品 -->
				<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1300px" class="dialog">
					<selectshop :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :selectedData="ruleForm.RecommendProudctList"
						@getSelectproList="getSelectproList" :goodsMaxlength='50' :visible.sync="addProductDialogShow" v-if="addProductDialogShow">
					</selectshop>
				</el-dialog>

				
				<!-- 选择页面 -->
				<linkModal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false" key="1"></linkModal>

				<div class="bottom-save-btn">
					<el-button style="width:240px" @click="canselEdit">取消</el-button>
					<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
				</div>
			</div>
		</creatsuccess>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		activitySpellGroupInfo,
		activitySpellGroupSave
	} from '@/api/TurnTomySelf.js'
	// import selectProduce from '@/components/SelectSingleProduce/lottery'
	import selectProduce from '../association/turntableActivities'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	import selectCoupon from '@/components/SelectMultipCoupon'
	import couponsdialog from "@/views/Customer/components/couponsdialog"
	import selectshop from '@/views/goods/selectgoodsElement.vue'
	import linkModal from '@/views/components/linkModal';

	import draggable from "vuedraggable";
	export default {
		components: {
			selectProduce,
			// giftdataProduct,
			// turntableActivities,
			creatsuccess,
			selectCoupon,
			couponsdialog,
			selectshop,
			draggable,
			linkModal
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var clustNumCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入成团人数'));
				} else if (value < 2 || value > 1000) {
					return callback(new Error('成团人数请设置在2~1000之间'));
				} else {
					return callback();
				}
			}
			var usefulDayCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入成团有效期'));
				} else if (value < 1 || value > 100) {
					return callback(new Error('成团有效期请设置在1~100之间'));
				} else {
					return callback();
				}
			}
			var limitNumberCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入限购数量'));
				} else if (value < 0 || value > 99999) {
					return callback(new Error('限购数量请设置在0~99999之间'));
				} else {
					return callback();
				}
			};

			var GroupPriceCheck = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = Number(index1)
				let value1 = this.ruleForm.ProductSpecList[index].Price
				// value1 规格价格   value 拼团价
				if (value >= value1) {
					return callback(new Error('拼团价需低于原价'));
				} 
				else if (!value && value !== 0) {
					return callback(new Error('请输入拼团价'));
				}
				else if (value < 0.01) {
					return callback(new Error('拼团价不可低于0.01元'));
				} else {
					return callback();
				}
			};
			var CouponCountCheck = (rule, value, callback) => {
				// console.log(value)
				if(value>0&&value<=99){
					callback()
				}else{
					callback(new Error('数量请设置在1~99之间，整数'))
				}
			};
			var RecommendProudctListCheck = (rule, value, callback) => {
				if(this.ruleForm.RecommendProudctType==1&&(!this.ruleForm.RecommendProudctList||!this.ruleForm.RecommendProudctList.length)){
					callback(new Error('请选择推荐商品'))
				}else{
					callback()
				}
			};
			return {
				lookPicture: 'https://cdn.dkycn.cn/Images/sellGroupImg.png',
				lookVisable: false,
				conflictGoodsList: [],
				conflictGoodsShow: false,
				imgUrl: config.IMG_BASE,
				imgapi: config.UPLOAD_IMG,
				loading: false,
				couponData: {},
				detailLoading: false,
				ruleForm: {
					StartTime: '',
					EndTime: '',
					radioLimit: '0',
					Quota: '',
					ProductSpecList: [],
					IsSuperpositionCoupon: null,
					ProductType: '',
					ProductName: '',
					ImgUrl: '',
					GroupMemberNum: '',
					EffectiveGroupDay: '',
					IsOpenActiveGroup: true,
					IsOpenGroupLeaderFree: false,
					IsOpenSimulationGroup: false,
					// Delstatus:'',
					IsOnlyShowOfficialGroup:false,
					IsSendGroupSuccessReward:false,
					ActivityCouponList:[],
					tablelist:[],
					RecommendProudctType:0,
					RecommendProudctList:[],
					AdvertisementImg:'',
					MenuType:0,
					PageName:'',
					PagePath:'',
					IsOpenIncreaseFans:false,
					IncreaseFansQrCode:'',
					IncreaseFansEntranceImg:'',
				},
				rules: {
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
					GroupMemberNum: [{
						required: true,
						validator: clustNumCheck,
						trigger: 'blur'
					}],
					EffectiveGroupDay: [{
						required: true,
						validator: usefulDayCheck,
						trigger: 'blur'
					}],
					Quota: [{
						required: true,
						validator: limitNumberCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					IsSuperpositionCoupon: [{
						required: true,
						message: '请配置优惠券叠加方式',
						trigger: 'change'
					}],
					GroupPrice: [{
						required: true,
						validator: GroupPriceCheck,
						trigger: 'blur'
					}],
					ActivityCouponList:[{
						required: true,
						message: '请选择赠送的优惠券',
						trigger: 'blur'
					}],
					CouponCount:[{
						required: true,
						validator: CouponCountCheck,
						trigger: 'blur'
					}],
					RecommendProudctList:[{
						required: true,
						validator: RecommendProudctListCheck,
						trigger: 'blur'
					}],
					IncreaseFansQrCode:[{
						required: true,
						message: '请上传涨粉二维码',
						trigger: 'blur'
					}],
					IncreaseFansEntranceImg:[{
						required: true,
						message: '请上传涨粉入口图片',
						trigger: 'blur'
					}]
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				selectProShow: false,
				// checkShowId:0,
				proCheckedId: 0,
				selectProductlist: {}, //选中的活动商品和规格
				currentEditStatus: null,
				currentEditType: null,
				saveLoading: false,
				
				//拼团改版
				IsSupplierProduct:false,

				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:6,
				},

				currentPage:1,
				pagesize:5,
				couponstotal:0,
				selectCouponShow:false,

				addProductDialogShow:false,
				shopVisible:false,
			}
		},
		created() {
			this.currentEditStatus = this.$route.query.status
			this.currentEditType = this.$route.query.type
			//获取详情
			if (this.$route.query.Id > 0) {

				this.getInformation()
			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			removelink(){
				this.ruleForm.PagePath = ''
				this.ruleForm.PageName = ''
				this.ruleForm.MenuType = 0
			},
			dataChange(e){
				// console.log(this.geturl(e))
				this.ruleForm.PagePath = this.geturl(e).url
				this.ruleForm.PageName = e.text
				this.ruleForm.MenuType = this.geturl(e).type
				// console.log(e,this.geturl(e),123)
			},
			geturl(record){
				let data = {
					url:'',
					type:'',
				}
				if (record.type == "classify") {
          data.url = 'pages/productGroup/productGroup?id=' + record.data.Id
					data.type = 1
        } else if (record.type == "functional") {
          // 全部商品
					data.type = 2
          if (record.data.id == 1) {
            data.url = 'pages/index/index'
          } else if (record.data.id == 2) {
            data.url = 'pages/allProduct/allProduct'
          } else if (record.data.id == 3) {
            // 个人中心
            data.url = 'pages/personalCenter/mine/mine'
          } else if (record.data.id == 4) {
            // 购物车
            data.url = 'pages/shoppingCart/shoppingCart'
          } else if (record.data.id == 5) {
            // 积分中心
            data.url = 'pages/pointsCenter/pointsCenter'
          } else if (record.data.id == 6) {
            // 每日签到
            data.url = 'pages/userSign/userSign'
          } else if (record.data.id == 7) {
            // 客服
            data.url = 'pages/customerService/customerService'
          } else if (record.data.id == 8) {
            // 订单列表
            data.url = 'pages/order/myOrder/myOrder'
          } else if (record.data.id == 9) {
            // 分销礼包
            data.url = 'pages/invitation/invitationCard/invitationCard'
          }else if (record.data.id == 10) {
            // 成为会员
            data.url = 'pageA/pages/openSuccess/openSuccess'
          }else if (record.data.id == 11) {
            // 助力直播列表
            data.url = 'pageA/pages/live/live'
          }else if (record.data.id == 12) {
            // 扫码购
            data.url = 'pageA/pages/scan/scan'
          }else if (record.data.id == 13) {
            // 充值中心
            data.url = 'pageA/pages/balanceRecharge/balanceRecharge'
          }else if (record.data.id == 14) {
            // 群分享
            data.url = 'pageA/pages/groupShare/groupShare'
          }else if (record.data.id == 15) {
            // 全部信息页
            data.url = 'pageA/pages/information/information'
          }else if (record.data.id == 16) {
						// 短视频带货
						data.url =  'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						data.url = 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						data.url = 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						data.url = 'pageC/pages/AIGC/home'
					}
        } else if (record.type == "product") {
          data.url = 'pages/detail/detail?id=' + record.data.Id
					data.type = 3
        } else if (record.type == "custom") {
					data.type = 4
          data.url = 'pages/cusPage/cusPage?id=' + record.data.Id
        }else if (record.type == 'truntable'){
					data.type = 6
          data.url = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
        } else if (record.type == 'live'){
					data.type = 5
          data.url = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
        }else if (record.type == 'coupon'){
					data.type = 7
          data.url = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
        }else if (record.type == 'help') {
					data.type = 8
          data.url = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
        }else if (record.type == 'group') {
					data.type = 9
          data.url = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
        }else if (record.type == 'questionnaire') {
					data.type = 10
          data.url = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
        }else if (record.type == 'taketest') {
					data.type = 11
					data.url = `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					data.type = 12
					data.url = `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					data.type = 13
					data.url = `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					data.type = 14
					data.url = `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
				}
				return data
			},
			beforeUpload(file,max,name){
				const size = file.size / 1024 / 1024 < max;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error(`${name}大小请控制在${max}M以内`);
					return false
				}
			},
			onSuccess(file,name){
				// console.log(file)
				this.ruleForm[name] = file[0]
				this.$forceUpdate()
			},
			delshop(e){
				this.ruleForm.RecommendProudctList.splice(e,1)
			},
			getSelectproList(list){
				// console.log(list)
				this.ruleForm.RecommendProudctList = list
				this.addProductDialogShow = false
			},
			removecoupon(row){
				this.ruleForm.ActivityCouponList = this.ruleForm.ActivityCouponList.filter(v=>{
					return v.Id!=row.Id
				})
				this.couponstotal = this.ruleForm.ActivityCouponList.length
				this.computedlist()
			},
			getSelectCouponList(list){
				// console.log(list)
				if(!list||!list.length){
					this.$message.error('请选择赠送的优惠券')
					return
				}
				let oldlist = JSON.parse(JSON.stringify(this.ruleForm.ActivityCouponList))
				this.ruleForm.ActivityCouponList = JSON.parse(JSON.stringify(list)).map(v=>{
					v.CouponCount = 1
					v.ActivityCouponId = v.Id
					let old = oldlist.find(x=>x.Id==v.Id)
					if(old&&old.Id){
						v.CouponCount = old.CouponCount
					}
					return v
				})
				this.computedlist()
				this.selectCouponShow = false
				this.$forceUpdate()
			},
			handleSizeChange(e){
				this.currentPage = 1
				this.pagesize = e
				this.computedlist()
			},
			handleCurrentChange(e){
				this.currentPage = e
				this.computedlist()
			},
			computedlist(){
				this.ruleForm.tablelist = this.ruleForm.ActivityCouponList.filter((v,i)=>{
					// v.CouponCount = v.CouponCount||''
					return i>=(this.currentPage-1)*this.pagesize&&i<this.currentPage*this.pagesize
				})
				this.couponstotal = this.ruleForm.ActivityCouponList.length
				if((!this.ruleForm.tablelist||!this.ruleForm.tablelist.length)&&this.currentPage>1){
					this.currentPage--
					this.computedlist()
				}
				// console.log(this.ruleForm.ActivityCouponList)
			},
			recovery(){
				this.$router.push({path:this.$route.path,query:{
					type:2,
					id:0,
					status:''
				}})
				this.showtype.issuccess = false
				this.ruleForm =  {
					StartTime: '',
					EndTime: '',
					radioLimit: '0',
					Quota: '',
					ProductSpecList: [],
					IsSuperpositionCoupon: null,
					ProductType: '',
					ProductName: '',
					ImgUrl: '',
					GroupMemberNum: '',
					EffectiveGroupDay: '',
					IsOpenActiveGroup: true,
					IsOpenGroupLeaderFree: false,
					IsOpenSimulationGroup: false
					// Delstatus:'',
				}
			},
			//团长免单开启
			changeTeamfreeTrue() {
				this.ruleForm.IsOpenSimulationGroup = false
			},
			//模拟开团开启
			changeTestTrue() {
				this.ruleForm.IsOpenGroupLeaderFree = false
			},
			//查看示例图
			CheckExample(e) {
				this.lookPicture = e
				this.lookVisable = true
			},
			//限制输入框只能输入整数
			onlyclustNum(obj, index) {
				// index 1 成团人数 2 成团有效期

				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				if (index == 1) {
					this.ruleForm.GroupMemberNum = obj
				} else {
					this.ruleForm.EffectiveGroupDay = obj
				}
			},
			onlyNumber(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				this.ruleForm.ProductSpecList[index].GroupPrice = obj
				// this.$forceUpdate()
				// console.log(this.ruleForm.ProductSpecList[index].ExclusivePrice,'赋值不对吗')
			},
			noChangeLimit() {
				this.$forceUpdate()
				this.ruleForm.radioLimit = this.ruleForm.radioLimit
			},
			YesChangeLimit() {
				this.$forceUpdate()
				this.ruleForm.radioLimit = this.ruleForm.radioLimit
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true
							if (this.ruleForm.StartTime == this.ruleForm.EndTime) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请设置正确的时间'
								})
								return
							}
							if (!this.ruleForm.ProductSpecList.length) {
								this.$common.completeFormInformation('活动商品至少选择一件', 'is-error-button')
								return
							}
							let GroupPrices = this.ruleForm.ProductSpecList.filter(item => {
								return !item.GroupPrice || Number(item.GroupPrice) == 0
							})
							if (GroupPrices.length) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '拼团价需大于0，最多2位小数'
								})
								return
							}
							if(this.ruleForm.IsSendGroupSuccessReward){
								for(let index in this.ruleForm.ActivityCouponList){
									let item = this.ruleForm.ActivityCouponList[index]
									if(item.CouponCount<1||item.CouponCount>99||item.CouponCount!=parseInt(item.CouponCount)){
										this.currentPage = Math.ceil(index/5)+1
										// console.log(this.currentPage,'this.currentPage')
										this.computedlist()
										this.$nextTick(()=>{
											this.$refs[formName].validate()
										})
										return
									}
								}
							}
							let data = {
								StartTime: this.ruleForm.StartTime,
								EndTime: this.ruleForm.EndTime,
								Quota: this.ruleForm.radioLimit == 1 ? this.ruleForm.Quota : 0,
								IsSuperpositionCoupon: this.ruleForm.IsSuperpositionCoupon,
								ProductSpecList: this.ruleForm.ProductSpecList,
								GroupMemberNum: this.ruleForm.GroupMemberNum,
								EffectiveGroupDay: this.ruleForm.EffectiveGroupDay,
								IsOpenActiveGroup: this.ruleForm.IsOpenActiveGroup,
								IsOpenGroupLeaderFree: this.ruleForm.IsOpenGroupLeaderFree,
								IsOpenSimulationGroup: this.ruleForm.IsOpenSimulationGroup,
								ProductId: this.proCheckedId,
								IsOnlyShowOfficialGroup:this.ruleForm.IsOpenActiveGroup?this.ruleForm.IsOnlyShowOfficialGroup:false,
								IsSendGroupSuccessReward:this.ruleForm.IsSendGroupSuccessReward,
								ActivityCouponList:this.ruleForm.ActivityCouponList.map(v=>{
									v.SendActivityCouponCount = v.CouponCount
									return v
								}),
								RecommendProudctType:this.ruleForm.RecommendProudctType,
								RecommendProudctList:this.ruleForm.RecommendProudctList.map(v=>v.ProductId),
								AdvertisementImg:this.ruleForm.AdvertisementImg,
								MenuType:this.ruleForm.MenuType,
								PageName:this.ruleForm.PageName,
								PagePath:this.ruleForm.PagePath,
								IsOpenIncreaseFans:this.ruleForm.IsOpenIncreaseFans,
								IncreaseFansQrCode:this.ruleForm.IncreaseFansQrCode,
								IncreaseFansEntranceImg:this.ruleForm.IncreaseFansEntranceImg,
							}
							if (this.$route.query.type == 1 && this.$route.query.Id > 0) {
								data.Id = this.$route.query.Id
							} else {
								data.Id = 0
							}

							let result = await activitySpellGroupSave(data)
							if (result.Result && result.Result.length) {
								// this.$message({
								// 	showClose: true,
								// 	type: 'error',
								// 	message: '操作失败'
								// });
								this.conflictGoodsList = result.Result
								this.conflictGoodsShow = true
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								if(this.$route.query.Id==0||this.$route.query.type==2){
									this.showtype = {
										issuccess:true,
										suctype:6,
										id:result.Result.Id,
										ProductId:this.proCheckedId
									}
								}else{
									this.$router.push({
										path: '/market/sellGroupBox/sellGroup'
									});
								}
							}


						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {

							this.saveLoading = false
						}


					} else {

						this.$common.completeFormInformation('请完善活动信息', 'is-error')
						console.log('error submit!!');
						return false;
					}
				});

			},
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/sellGroupBox/sellGroup'
				});
			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			rowKeys(row) {
				return row.ProductSpecId
			},
			handlePrizePro() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					// 添加复制是2  (编辑)
					this.couponData.id = this.$route.query.type == 2 ? 0 : this.ruleForm.Id
					this.couponData.StartTime = this.ruleForm.StartTime
					this.couponData.EndTime = this.ruleForm.EndTime
					this.selectProShow = true
				}
			},
			getSelectPros(selection, isChecked) {

				// console.log(selection, isChecked, '宣中锋的')
				if (!isChecked) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '活动商品至少选择一件'
					})
					return
				} else {
					this.selectProductlist = selection
					this.IsSupplierProduct = this.selectProductlist.IsSupplierProduct
					// console.log(this.selectProductlist,'已经选择的商品')
					this.ruleForm.ProductName = this.selectProductlist.Name
					this.ruleForm.ProductType = this.selectProductlist.ProductType
					this.ruleForm.ImgUrl = this.selectProductlist.ImgUrl
					this.ruleForm.ProductSpecList = this.selectProductlist.ProductSpecList
					this.proCheckedId = this.selectProductlist.Id
				}
				this.selectProShow = false


			},
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					let data = {
						Id: this.$route.query.Id,
						IsCopy: this.$route.query.type == 2 && this.$route.query.Id ? true : false
					}
					let result = await activitySpellGroupInfo(data)
					this.ruleForm = result.Result
					this.ruleForm.ActivityCouponList = JSON.parse(JSON.stringify(this.ruleForm.ActivityCouponList)).map(v=>{
						v.CouponCount = v.SendActivityCouponCount
						v.ActivityCouponId = v.Id
						return v
					})
					this.computedlist()
					this.IsSupplierProduct = result.Result.IsSupplierProduct
					//是复制 且 不是添加
					if (this.$route.query.type == 2 && this.$route.query.Id) {
						if (this.ruleForm.ProductDelstatus) {
							this.ruleForm.ProductSpecList = []
							this.proCheckedId = 0
						}
						// this.ruleForm.ProductSpecList.forEach(item =>{
						// 	item.ExclusivePrice = item.ExclusivePrice?item.ExclusivePrice : ''
						// })
						// this.ruleForm.ProductSpecList = JSON.parse(JSON.stringify(this.ruleForm.ProductSpecList))
						// console.log('123',this.ruleForm.ProductSpecList)
					}
					this.ruleForm.RecommendProudctList = this.ruleForm.RecommendProudctList.map(v=>{
						v.ProductId = v.Id
						return v
					})
					if (this.ruleForm.Quota > 0) {
						this.ruleForm.radioLimit = '1'
					} else {
						this.ruleForm.radioLimit = '0'
					}
					if (this.$route.query.type == 2) {
						this.ruleForm.StartTime = ''
						this.ruleForm.EndTime = ''
					}

					if (this.ruleForm.ProductSpecList.length) {
						this.proCheckedId = this.ruleForm.ProductSpecList[0].ProductId
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.detailLoading = false
				}

			},
		}
	}
</script>

<style lang="less" scoped>
.w80-h80{
	::v-deep .el-upload{
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.flex-row{
	display: flex;
}
.w750-h180{
	::v-deep .el-upload{
		width: 375px;
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.shoplist-box{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;
	.demo{
		// border:1px solid black;
		width: 80px;
		.subcoat{
			position: relative;
			// border:1px solid black;
			img{
				width: 80px;height: 80px;
				// border:1px solid black;
				// vertical-align: middle;
			}
			.close{
				position: absolute;
				top: -8px;
				left: calc(100% - 8px);
				// width: 16px;height: 16px;
				border-radius: 50%;
				// border:1px solid black;
				cursor: pointer;
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.font-box{
				text-align:center;background:#F5F5F5FF;
				line-height: 2;
				// border:1px solid black;
				padding: 0px 3px;
			}
		}

	}
}
	.editgiftdetails {
		padding: 10px;
		background: #fff;
		.specialErrorTable{
			::v-deep .el-form-item__error{
				width: auto!important;
				padding-top: 0px!important;
				position:initial!important;
				margin-top: 8px!important;
			}
		}
		::v-deep .el-form-item__error {
			width: 180px !important;
			padding-top: 9px;
		}

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 999;
			box-shadow: 13px 1px 6px #999;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		// .number__input {
		// 	border: 1px solid #ccc;
		// }


	}
	.errtips{
		color: #F56C6C;
    font-size:12px;
	}
</style>
<style lang="less" scoped>
	.nostar{
		::v-deep .el-form-item__label::before{
			content: ' ' !important;
		}
	}
</style>
